import React from 'react';

const Fellowship = () => {
  return (
    <div className="section gray" style={{padding: '150px 25px 150px 25px'}}>
      <div className="wrapper-full">
        <div className="center-row w-row">
          <div
            className="left-content"
          >
            <h1 className="section-header">BIBLE STUDY &amp; FELLOWSHIP GROUPS</h1>
            <p>As a member of BPCBC, we encourage you to stay connected through our weekly and bi-weekly fellowship groups.</p>

            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Study</th>
                  <th>Frequency *</th>
                  <th>Leader / Contact</th>
                  <th>Notes</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Galatians</td>
                  <td>Tuesday @ 8pm</td>
                  <td>Student Ministry</td>
                  <td>Ages 12-18</td>
                </tr>
                <tr>
                  <td>Genesis</td>
                  <td>Wednesday @ 7:30pm</td>
                  <td>Ptr. Nate</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>Genesis</td>
                  <td>Wednesday @ 8pm *</td>
                  <td>Women's Group / Chrissie or Irene</td>
                  <td>Women<br />Occurs Wednesday every 2 weeks</td>
                </tr>
                <tr>
                  <td>Sermon on the Mount</td>
                  <td>Friday @ 7:30pm</td>
                  <td>Bro. Lito Ira</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>Romans</td>
                  <td>Friday @ 8pm</td>
                  <td>Student Ministry</td>
                  <td>Located at BPCBC</td>
                </tr>
                <tr>
                  <td>1 Peter</td>
                  <td>Saturday @ 6pm</td>
                  <td>Bro. Marlon</td>
                  <td>&nbsp;</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={4}>* Weekly unless otherwise noted.</td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Fellowship;
