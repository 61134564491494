import React from 'react';

const Header: React.FC = () => {
  return (
    <div className="hero-section contact">
      <div className="wrapper-full">
        <div className="left-hero-content">
          <div className="line-block w-clearfix">
            <div className="subtitle-line"></div>
            <div className="sub-hero-tittle">ANNOUNCEMENT</div>
          </div>
          <h1 className="white-heading">Worship Service Update</h1>
          <p className="hero-paragraph header" style={{ width: '80%' }}>
            "For God so loved the world, that he gave his only Son, 
            that whoever believes in him should not perish but have 
            eternal life." - John 3:16
          </p>
        </div>
      </div>
    </div>
  );
};

export default Header;
