import React from 'react';

const Registration = () => {
  return (
    <div className="section gray" style={{ padding: '150px 25px 150px 25px' }}>
      <div className="wrapper-full">
        <div className="center-row w-row">
          <div className="left-content">
            <h1 className="section-header">VBS 2022</h1>

            {/* <h2>Register</h2>
            <iframe
              title="VBS 2022 promo"
              width="560"
              height="315"
              src="https://www.youtube.com/embed/n1509QIUxCg"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen={true}
            ></iframe>
            <p>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://docs.google.com/forms/u/2/d/1jxw2aUJKmI1I3UpdqfP4pq3qfHALc8J186IfNZI0KQk/edit?usp=forms_home&ths=true"
                  className="w-button"
                >
                  REGISTER NOW
                </a>
            </p>

            <h4>SAVE THE DATE: </h4>
            <p>
              Zoomerang VBS will be held on Wednesday, July 6, 2022 - Sunday,
              July 10, 2022.{' '}
            </p>
            <p>
            <ul>
              <li>Wednesday: 6:30pm - 8:45pm</li>
              <li>Thursday: 6:30pm - 8:45pm</li>
              <li>Friday: 6:30pm - 8:45pm</li>
              <li>Saturday: 10:00am - 1:00pm</li>
              <li>Sunday @ 9am: Families, join us for Sunday Worship.</li>
            </ul>
            </p> */}


            {/* <h2 style={{
              margin: "100px 0 0 0"
            }}>Volunteers</h2>
            <p>If you are a member of BPCBC and are interested in helping, click on the registration button below.</p>

            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://forms.gle/vm6VpeRq5xRa3u7Z7"
              className="w-button">I WANT TO VOLUNTEER</a>
               */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Registration;
