import React from 'react';

const Church = () => {
  return (
    <div className="container-fluid text-left">

      <iframe
        title="live stream"
        width="560"
        height="315"
        src="https://www.youtube.com/embed/live_stream?channel=UCGf06mwPeFY2-1qrjvh58fg"
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
      <h1 className="mt-2">Sunday School - Church</h1>
      <h2>Outline</h2>
      <ul>
        <li>1. July 21: What is the Church</li>
        <li>2. July 28: The Visible Church and Importance of Membership</li>
        <li>3. Aug 4: Priorities of the early church: Teaching + Prayer</li>
        <li>Aug 11: No Sunday School - Church Anniversary</li>
        <li>4. Aug 18: Priorities of the early church: Fellowship + Communion</li>
        <li>5. Aug 25: Priorities of the early church: Evangelism</li>
        <li>6. Sep 1: Priorities of the early church: Discipleship</li>
        <li>Sep 8: No Sunday School - Church Camp</li>
        <li>7. Sep 15: Spiritual Leadership: Elders and Deacons</li>
        <li>8. Sep 22: Spiritual Gifts and Ministry</li>
        <li>9. Sep 29: Unity and Purity</li>
      </ul>

      <h2>Recommended Reading List</h2>
      <ul>
        <li>
          Biblical Eldership: An Urgent Call to Restore Biblical Church Leadership
        </li>
        <li>
          Biblical Theology: How the Church Faithfully Teaches the Gospel (9Marks:
          Building Healthy Churches)
        </li>
        <li>
          Christ's Call to Reform the Church: Timeless Demands From the Lord to His
          People
        </li>
        <li>
          Church Discipline: How the Church Protects the Name of Jesus (9Marks:
          Building Healthy Churches) (9Marks: Buliding Healthy Churches)
        </li>
        <li>
          Church Membership: How the World Knows Who Represents Jesus (9Marks:
          Building Healthy Churches)
        </li>
        <li>Church, A.W.Tozer</li>
        <li>Deliberate Church: Building Your Ministry on the Gospel</li>
        <li>
          High King of Heaven: Theological and Practical Perspectives on the Person
          and Work of Jesus
        </li>
        <li>Nine Marks of a Healthy Church (9Marks)</li>
        <li>
          Paul's Vision for the Deacons: Assisting the Elders with the Care of God's
          Church
        </li>
        <li>
          Prayer: How Praying Together Shapes the Church (9marks: Building Healthy
          Churches)
        </li>
        <li>
          Sound Doctrine: How a Church Grows in the Love and Holiness of God (9Marks
          Building Healthy Churches)
        </li>
        <li>
          Spiritual Gifts: What they are and why they matter, Thomas R. Schreiner
        </li>
        <li>The Body Dynamic, John MacArthur</li>
        <li>The Gospel and Personal Evangelism (Redesign) (9marks)</li>
        <li>The Master's Plan for the Church</li>
        <li>
          The Trellis and the Vine: The Ministry Mind-Shift That Changes Everything
        </li>
        <li>What Is a Healthy Church Member?</li>
      </ul>

      <h4 className="mt-5">2. Church Membership</h4>
      <p>
        <a
          href="https://meet.ps/church-membership"
          rel="noopener noreferrer"
          target="_blank"
          className="btn btn-primary disabled"
        >
          Questions and Poll
        </a>
      </p>

      <h4 className="mt-5">3. Priorities of the early church: Teaching + Prayer</h4>

      <h5>Bible Studies</h5>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Day</th>
            <th>Location</th>
            <th>Contact</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Wednesday</td>
            <td> Baldwin Park</td> <td>Ptr. Nate</td>
          </tr>
          <tr>
            <td>Wednesday</td>
            <td> Buena Park/Anaheim </td>
            <td> Ptr. Tec</td>
          </tr>
          <tr>
            <td>Thursday</td>
            <td> Norwalk </td>
            <td> Lito Ira</td>
          </tr>
          <tr>
            <td>Friday</td>
            <td> La Mirada </td>
            <td> Ptr. Nate</td>
          </tr>
          <tr>
            <td>Friday</td>
            <td> Baldwin Park (English) </td>
            <td> Adrian Valverde</td>
          </tr>
          <tr>
            <td>Saturday</td>
            <td> Artesia </td>
            <td> Ptr. Tec</td>
          </tr>
        </tbody>
      </table>
      <p>
        <a
          href="https://meet.ps/church-priorities-1"
          rel="noopener noreferrer"
          target="_blank"
          className="btn btn-primary disabled"
        >
          Questions and Poll
        </a>
      </p>

      <h4 className="mt-5">
        4. Priorities of the early church: Fellowship + Communion
      </h4>

      <p>
        <a
          href="https://meet.ps/church-priorities-2"
          rel="noopener noreferrer"
          target="_blank"
          className="btn btn-primary disabled"
        >
          Questions and Poll
        </a>
      </p>

      <h4 className="mt-5">5. Priorities of the early church: Evangelism</h4>

      <p>
        <a
          href="https://meet.ps/church-priorities-evangelism"
          rel="noopener noreferrer"
          target="_blank"
          className="btn btn-primary disabled"
        >
          Questions and Poll
        </a>
      </p>

      <h4 className="mt-5">6. Priorities of the early church: Discipleship</h4>

      <p>
        <a
          href="https://meet.ps/church-priorities-discipleship"
          rel="noopener noreferrer"
          target="_blank"
          className="btn btn-primary disabled"
        >
          Questions and Poll
        </a>
      </p>

      <h4 className="mt-5">7. Spiritual Leadership: Elders and Deacons</h4>

      <p>
        <a
          href="https://meet.ps/church-leadership"
          rel="noopener noreferrer"
          target="_blank"
          className="btn btn-primary disabled"
        >
          Questions and Poll
        </a>
      </p>

      <h4 className="mt-5">8. Spiritual Gifts and Ministry</h4>

      <p>
        <a
          href="https://meet.ps/church-spiritual-gifts"
          rel="noopener noreferrer"
          target="_blank"
          className="btn btn-primary disabled"
        >
          Questions and Poll
        </a>
      </p>

      <h4 className="mt-5">9. Unity and Purity</h4>

      <p>
        Excerpt from Biblical Doctrine:{' '}
        <a
          href="https://s3-us-west-2.amazonaws.com/bpcbc.org/website/BD+-+Unity+and+Purity.pdf"
          rel="noopener noreferrer"
          target="_blank"
        >
          Unity and Purity
        </a>
      </p>
      <p>
        <a
          href="https://meet.ps/church-unity-purity"
          rel="noopener noreferrer"
          target="_blank"
          className="btn btn-primary"
        >
          Questions and Poll
        </a>
      </p>
    </div>
  );
};

export default Church;
