/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';

const Footer: React.FC = () => {
  return (
    <div className="footer">
      <div className="footer-menu">
        <div className="wrapper-full">
          <div className="footer-on-flex">
            <div className="column-footer">
              <div>
                <div className="footer-tittle">
                  <strong className="bold-text-2">SUNDAY SERVICES</strong>
                </div>
                <p className="white-paragraph transparence">
                  Sunday Worship: <strong>9:00 am</strong>
                  <br />
                  Sunday School: <strong>11:00 am</strong>
                  <br />
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className="footer-nav-link transparence"
                    href="https://www.youtube.com/channel/UCGf06mwPeFY2-1qrjvh58fg/live"
                  >
                    Live Stream
                  </a>
                </p>
              </div>
            </div>
            <div className="column-footer">
              <div>
                <div className="footer-tittle">
                  <strong className="bold-text-2">LOCATION</strong>
                </div>
                <p className="white-paragraph transparence">
                  <strong>Baldwin Park Community Bible Church</strong>
                  <br />
                  14952 Pacific Ave. Baldwin Park CA 91706 <br />
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className="footer-nav-link transparence"
                    href="http://maps.google.com/maps?saddr=Current%20Location&daddr=14952%20Pacific%20Ave.%20Baldwin%20Park%20CA%2091706"
                  >
                    Maps and Directions
                  </a>
                </p>
              </div>
            </div>
            <div className="column-footer">
              <div>
                <div className="footer-tittle">
                  <strong className="bold-text-2">CONNECT</strong>
                </div>
                <p className="white-paragraph">
                  <a
                    href="mailto:info@bpcbc.org"
                    className="footer-nav-link transparence"
                  >
                    info@bpcbc.org
                  </a>
                </p>
                <div className="social-link-team footer">
                  <a href="https://www.instagram.com/bpcbc/" rel="noopener noreferrer" target="_blank" className="social-link-block _3 w-inline-block"></a>
                  <a href="https://www.facebook.com/BPCBC/" rel="noopener noreferrer" target="_blank" title="facebook" className="social-link-block _4 w-inline-block"></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="wrapper-full">
        <div className="copy-box">
          Copyright © {new Date().getFullYear()} Baldwin Park Community Bible Church.
          All Rights Reserved.
        </div>
      </div>
    </div>
  );
};

export default Footer;
