import React, {useState, useEffect} from 'react';
import Header from '../components/home/Header';
import SectionContent from '../components/home/SectionContent';
import Tiles from '../components/home/Tiles';
import Updates from '../components/home/Updates';
import {getUpdates, UpdateContent} from '../shared/updates';

const Home: React.FunctionComponent = () => {
  // const [items, setItems] = useState<UpdateContent[]>([]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const result = await getUpdates();
  //     setItems(result);
  //   };
  //   fetchData();
  // }, []);
  return (
    <>
      <Header />
      {/* <Updates updates={items} /> */}
      <SectionContent />
      <Tiles />
    </>
  );
};

export default Home;