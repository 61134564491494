import React from 'react';

const Devotion = () => {
    return (
        <div className="section gray" style={{padding: '150px 25px 150px 25px'}}>
            <div className="wrapper-full">
                <div className="center-row w-row">
                    <div  className="left-content">
                        <h1 className="section-header">Devotion</h1>
                        <p>This is a temp page to present what our church believes</p> 
          </div>
        </div>
      </div>
    </div>
    );
};

export default Devotion;