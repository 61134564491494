import React from 'react';

const Give = () => {
  return (
    <div className="section gray" style={{ padding: '75px 25px 25px 25px' }}>
      <div className="wrapper-full">
        <div className="center-row w-row">
          <div
            className="left-content"
          >
            <div  className="left-content">
              <h1>The Cheerful Giver</h1>
                        <p>2 Corinthians 9:6-8</p>
                        <p>"The point is this: whoever sows sparingly will also reap
                          sparingly, and whoever sows bountifully will also reap
                          bountifully. Each one must give as he has decided in his 
                          heart, not reluctantly or under compulsion for God loves 
                          a cheerful giver. And God is able to make all grace abound 
                          to you, so that having all sufficiency in all things at all
                          times you may abound in every good work."</p> 
          </div>
            <h1 className="section-header">GIVING</h1>
            <h3>Ways to give</h3>

            <h4>> Drop it off</h4>
            <p>Drop off your offering at church Sunday, Tuesday, or Thursday between 8-5pm.</p>

            <h4>> Mail it</h4>
            <p>Send your check to: 14952 Pacific Ave. Baldwin Park CA 91706</p>

            <h4>> Zelle</h4>
            <p>You can zelle the church at <strong>give@bpcbc.org</strong> </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Give;
