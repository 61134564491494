import React from 'react';

const Tiles: React.FC = () => {
  return (
    <div className="section no-top-padding">
      <div className="wrapper-full">
        <div className="features-blocks">
          <div className="features-block">
            <div className="service-contact fourth"></div>
            <h5 className="service-tittle">MESSAGE SERIES</h5>
            <p>We are currently studying the book of James.</p>
            {/* <div className="top-padding">
              <a href="/ministries" className="stories-link black">
                READ&nbsp;MORE
              </a>
            </div> */}
          </div>
          <div className="features-block center-border about">
            <div className="service-contact fifth"></div>
            <h5 className="service-tittle">LIVE STREAM</h5>
            <p>Watch our live stream and subscribe for notifications.</p>
            <div className="top-padding">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.youtube.com/channel/UCGf06mwPeFY2-1qrjvh58fg/live"
                className="stories-link black w--current"
              >
                WATCH NOW
              </a>
            </div>
          </div>
          <div className="features-block">
            <div className="service-contact sixth"></div>
            <h5 className="service-tittle">GIVING</h5>
            <p>Support BPCBC ministries.</p>
            <div className="top-padding">
              <a
                href="/give"
                className="stories-link black"
              >
                DONATE
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tiles;
