import React from 'react';
import Registration from '../components/vbs/2022/Registration';

const VbsRegistration = () => {
  return (
    <>
      <Registration />
    </>
  );
};

export default VbsRegistration;
