import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from './pages/Home';
import Church from './pages/Church';
import Footer from './components/Footer';
import Navigation from './components/Navigation';
import Subscribe from './components/Subscribe';
import Give from './pages/Give';
import { useTracking } from './shared/useTracking';
import Fellowship from './pages/Fellowship';
import Devotion from './pages/Devotion';
import Vbs from './pages/Vbs';
import VbsRegistration from './pages/VbsRegistration';

const App: React.FC = () => {
  useTracking();

  return (
    <div>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/give" component={Give} />
        {/* <Route path="/vbs-registration">
          <VbsRegistration />
        </Route> */}
        <Route path="/vbs">
          <VbsRegistration />
          {/* <Vbs /> */}
        </Route>
        <Route path="/fellowship" component={Fellowship} />
        <Route path="/devotion" component={Devotion} />
        <Route path="/church" component={Church} />
      </Switch>
    </div>
  );
};

export default () => (
  <>
    <Router>
      <Navigation />
      <App />
    </Router>
    <Subscribe />
    <Footer />
  </>
);
