import React from 'react';

const Subscribe: React.FC = () => {
  return (
    <div className="section get-in-touch">
      <div className="wrapper-full">
        <div
          data-w-id="a2706da7-5ec9-0039-39a5-8d3f5b7205c9"
          className="section-content"
        >
          <img
            src="https://uploads-ssl.webflow.com/5e6da890e64d7d03f6d270cc/5e6da8903dea83a66733b3d1_icons8-spa-flower-96ss.png"
            width="39"
            alt=""
            className="flower"
          />
          <h2 className="section-tittle">
            SUBSCRIBE&nbsp;TO&nbsp;OUR
            <br />
            NEWSLETTER
          </h2>
          <p>Sign up with your email address to receive news and updates</p>
        </div>
        <div className="form-block-2 w-clearfix w-form">
          <form
            action="https://bpcbc.us19.list-manage.com/subscribe/post?u=61651ec47bbfd01a639e4f2da&amp;id=edb5d56ec8"
            method="post"
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            className="get-in-touch-form w-clearfix"
            target="_blank"
          >
            <input
              type="text"
              className="text-field-get-in w-input"
              name="email-2"
              data-name="Email 2"
              placeholder="Your email address"
              id="email-2"
              required={true}
            />
            <input
              type="submit"
              value="SUBSCRIBE"
              data-wait="Please wait..."
              className="get-in-touch-button w-button"
            />

            <div
              style={{
                position: 'absolute',
                left: '-5000px',
              }}
              aria-hidden="true"
            >
              <input
                type="text"
                name="b_61651ec47bbfd01a639e4f2da_edb5d56ec8"
                tabIndex={-1}
              />
            </div>
          </form>
          <div className="success-message w-form-done">
            <div className="text-block">
              Thank you! Your submission has been received!
            </div>
          </div>
          <div className="error-message w-form-fail">
            <div>Oops! Something went wrong while submitting the form.</div>
          </div>
        </div>
      </div>
      <div className="wrapper-full"></div>
    </div>
  );
};

export default Subscribe;
