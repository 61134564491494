import React from 'react';

const SectionContent: React.FC = () => {
  return (
    <div className="section gray" style={{ padding: '25px' }}>
      <div className="wrapper-full">
        <div className="center-row w-row">
          <div
            data-w-id="ce96775e-0635-6298-2a3d-8a68430dab86"
            className="left-content"
          >
            {/* <div className="line-block w-clearfix">
              <div className="subtitle-line"></div>
              <div className="sub-tittle gray">THE&nbsp;CHURCH</div>
            </div>*/}
            <h1 className="section-header">Welcome</h1>
            <p>Join us Sundays and throughout the week.</p>
            <p>
              <ul>
                <li>Sunday Service @ 9am</li>
                <li>Sunday School @ 11am</li>
                <li>Weekly fellowship: <a
                  href="/fellowship"
                >Fellowship groups</a></li>
              </ul>
            </p>
            <blockquote style={{fontSize: '1em'}}>
              For updates and announcements, please follow our{' '}
              <a
                href="https://www.facebook.com/BPCBC/"
                rel="noopener noreferrer"
                target="_blank"
              >
                facebook page
              </a>
              , join our Slack workspace by e-mailing{' '}
              <a href="mailto:slack@bpcbc.org">slack@bpcbc.org</a>, or refer to the
              church website.
            </blockquote>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionContent;
