import React from 'react';
import logoImage from '../assets/images/bpcbc-logo-charcoal.png';

const Navigation: React.FC = () => {
  return (
    <div
      data-collapse="medium"
      data-animation="default"
      data-duration="400"
      className="nav-bar w-nav"
    >
      <div>
        <a href="/" className="brand w-nav-brand">
          <img src={logoImage} alt="bpcbc logo" height="60" />
        </a>
        <nav role="navigation" className="nav-menu-2 w-nav-menu">
          <a href="/" aria-current="page" className="nav-link w-nav-link w--current">
            HOME
          </a>
          <a
            href="https://www.youtube.com/channel/UCGf06mwPeFY2-1qrjvh58fg/live"
            target="_target"
            aria-current="page"
            className="nav-link w-nav-link"
          >
            LIVE STREAM
          </a>
          <a
            href="/fellowship"
            aria-current="page"
            className="nav-link w-nav-link"
          >
            FELLOWSHIP
          </a>
          <a href="/devotion" 
             aria-current="page" 
             className="nav-link w-nav-link"
          >DEVOTION
          </a>
          {/* <a
            href="/vbs"
            aria-current="page"
            className="nav-link w-nav-link"
          >
            VBS
          </a> */}
          <a
            href="/give"
            aria-current="page"
            className="nav-link w-nav-link"
          >
            GIVE
          </a>
        </nav>
        <div className="menu-button w-nav-button">
          <div className="w-icon-nav-menu"></div>
        </div>
      </div>
      <div className="w-nav-overlay" data-wf-ignore=""></div>
    </div>
  );
};

export default Navigation;
